import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";

export default function Sports() {
  return (
    <Layout>
      <div className="sports">
        <h1 className="heading">Sports</h1>

        <p className="sports__text">
          Sports and physical activities are a vital part of school education as
          they lay a strong foundation for a healthy lifestyle at an early age.
          <br />
          <br />
          Chinmaya Vidyalaya, Vasant Vihar knows how to strike a perfect balance
          in academic and co- curricular activities; aiming for perfection in
          everything including sports. The Vidyalaya provides the best
          facilities, giving students the opportunity to enjoy a wide range of
          physical activities not just limited to basketball, football,
          volleyball, and numerous indoor games.
          <br />
          <br />
          The Vidyalaya provides ample opportunities to students in sports
          keeping their age, talent and ability into consideration.
          <br />
          <br />
          The activities performed under the able guidance of the coaches, offer
          immense satisfaction and our students leave no stone unturned in
          bringing laurels to the school that motivates them to set new
          milestones in life.
        </p>

        <div className="sports__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-12 col-md-6 col-xl-4">
                <img className="sports__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
