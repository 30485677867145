const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/sports/8-dec-23/pic1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/8-dec-23/pic2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/8-dec-23/pic3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/pic1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/pic2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/pic3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/pic4.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/pic5.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/pic6.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports/pic7.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/sports-day/sports-2022.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/IMG_4672.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/IMG_4673.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/IMG_4676.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/IMG_4677.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/sport1.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/sport2.png",
];

export default data;
